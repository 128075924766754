<template>
  <v-form v-model="valid" @submit.stop.prevent="onSubmit">
    <v-card class="elevation-12">
      <v-toolbar color="info" flat dark>
        <v-toolbar-title>
          <span>{{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" :to="{ name: 'login' }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text v-if="!finished">
        <ApiError :errors="errors"></ApiError>

        <v-text-field
          v-model="form.username"
          :rules="rules.username"
          label="Email or Username"
          name="email"
          prepend-icon="mdi-account"
          type="text"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-text v-if="finished"
        ><p class="text-center">
          An email has been sent with more instructions.
        </p>
        <p class="text-center">
          Please allow a minute or two for the email to arrive.
        </p></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="primary" :loading="loading" :disabled="loading" v-if="!sent" @click="onSubmit"
          >Request New Password</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import ApiError from "../display/ApiError";

export default {
  name: "ForgotPasswordForm",
  metaInfo: {
    title: "Forgot Password"
  },
  components: {
    ApiError
  },
  data() {
    return {
      valid: false,
      loading: false,
      finished: false,
      form: {},
      title: "Forgot Password",
      errors: {},
      rules: {
        username: [
          v => !!v || "E-mail or username is required"
          //v => /.+@.+/.test(v) || "E-mail must be valid"
        ]
      },
      sent: false
    };
  },
  mounted() {
    // clear existing errors
    this.$store.dispatch("logout");
  },
  methods: {
    onSubmit() {
      // if invalid, prevent submission
      if (!this.valid) {
        return;
      }

      // Set loading spinner
      this.loading = true;
      this.errors = {};

      // clear existing errorsç
      this.$store.dispatch("logout");

      // submit login request
      this.$api
        .post("/api/users/forgotPassword", this.form)
        .then(() => {
          this.loading = false;
          this.finished = true;
          this.title = "Password Reset Email Sent";
          this.sent = true;
        })
        .catch(error => {
          this.loading = false;
          if (error && error.response && error.response.data && error.response.status == 404) {
            this.errors = this.$api.createError("No users enrolled with that email address");
          } else {
            this.errors = this.$api.getErrorsFromResponse(error);
          }
        });
    }
  }
};
</script>
